import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../use-actions';

export const useCreateGroupContact = () => {
	const { createGroupContact } = useActions(actions);
	return async values => {
		return await createGroupContact({ input: values });
	}
};

export const useDeleteGroupContact = () => {
	const { deleteGroupContact } = useActions(actions);
	return async values => {
		return await deleteGroupContact({ input: values });
	}
};

export const useFilterGroupContacts = () => {
	const { filterGroupContactsById } = useActions(actions);
	return async values => {
		// Prepare filter for groupContactsIds
		const listIds = values.map(id => ({ id: { eq: id } }));
		return await filterGroupContactsById({ filter: {
			or: listIds
		} });
	}
};