import { createAction } from 'redux-actions';

export const updateFilterPipelines = createAction(
    'UPDATE_FILTER_PIPELINES', 
    async value => await value
);

export const resetAllFilterPipelines = createAction(
    'RESET_ALL_FILTER_PIPELINES', 
    async value => await value
);

export const resetOneFilterPipelines = createAction(
    'RESET_ONE_FILTER_PIPELINES', 
    async value => await value
);