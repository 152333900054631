import awsconfig from '../src/aws-exports';
import get from 'lodash/fp/get';
import split from 'lodash/split';

export const CURRENT_ENV = split(get('aws_user_files_s3_bucket', awsconfig), '-').pop()

export const EMAIL_PATTERN = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

export const WEBURL_PATTERN = new RegExp(/((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/);

export const PAGES_IDS = {
    INDOOR: '7uruqd0qREdvDGT6mvJSmM',
    OUTDOOR: 'EE5Y8KNxQfKh9YkGaXHrb',
    READY_MADE: 'tJ8akeWSeBSzkFKBArXzR',
    MATERIALS: '2zcNX5rCGSaJU7zzVG4sna'
};

export const PRODUCT_PAGES = [
	{ id: '7uruqd0qREdvDGT6mvJSmM', name: 'Indoor' },
	{ id: 'EE5Y8KNxQfKh9YkGaXHrb', name: 'Outdoor'},
    { id: 'tJ8akeWSeBSzkFKBArXzR', name: 'Ready Made'}
];

export const MATERIAL_PAGES = [
    { id: '7EfVcaWufa4v65NW1WDY7F', name: 'Indoor' },
	{ id: '4s9LbIiKUejeGeN6420fcb', name: 'Outdoor'},
];

export const INITIAL_FILTERS_CONTACTS = {
    company: null,
    title: null,
    city: null,
    zip_code: null,
    createdAt_start: null,
    createdAt_end: null,
    activity_start: null,
    activity_end: null,
    search: null,
    vip: false,
    vip_type: [],
    noGroup: false,
    noRegionGroup: false,
    noIndustryGroup: false,
    unassigned: false,
    groupsMailings: [], // multiple value
    groupsIndustry: [], // multiple value
    groupsRegion: [], // multiple value
    state: [], // multiple value
    assignedTo: [], // multiple value
    divisions: [] // multiple value
};

export const INITIAL_FILTERS_COMPANYS = {
    city: null,
    zip_code: null,
    vip: false,
    vip_type: [],
    createdAt_start: null,
    createdAt_end: null,
    search: null,
    size: { gt: false, lt: false },
    noGroup: false,
    unassigned: false,
    groupsMailings: [], // multiple value
    groupsIndustry: [], // multiple value
    groupsRegion: [], // multiple value
    assignedTo: [], // multiple value
    state: [], // multiple value
    divisions: [], // multiple value
};

export const INITIAL_FILTERS_PIPELINES = {
  // contact: null,
  // vip: false,
  // vip_type: [],
  archived: false,
  createdAt_start: null,
  createdAt_end: null,
  search: null,
  unassigned: false,
  assignedTo: [], // multiple value
  phase: [], // multiple value
  // sale_amount: [],
};

export const MATERIAL_PAGE_ID = '4RH1wni80CT4q6OJ4lDbQl';

export const OLD_MATERIAL_PAGE_ID = '2zcNX5rCGSaJU7zzVG4sna';

// export const GROUPS_DATA = [
//     { id: '1', name: 'SF'},
//     { id: '2', name: 'LA'},
//     { id: '3', name: 'Interior Design'},
//     { id: '4', name: 'Architect'},
//     { id: '5', name: 'End User'},
//     { id: '6', name: 'Newsletter Group'},
//     // { id: '6', name: ''}
// ];

export const GROUPS_DATA = [
  { id: '0', name: 'Newsletter', type: 'mailings'},
  { id: '1', name: 'San Francisco', type: 'region'},
  { id: '2', name: 'Los Angeles', type: 'region'},
  { id: '3', name: 'Architect', type: 'industry'},
  { id: '4', name: 'Interior Designer', type: 'industry'},
  { id: '5', name: 'Builder / Developer', type: 'industry'},
  { id: '6', name: 'Dealer', type: 'industry'},
  { id: '7', name: 'End user', type: 'industry'},
  { id: '8', name: 'Landscape Architect', type: 'industry'},
  { id: '9', name: 'Press or Real Estate', type: 'industry'}
]

export const CONTACTS_DATA = [{"id":"81898ddd-ae03-4107-bd27-d283213dc75d","first_name":"Shelli","middle_name":"Winfield","last_name":"Thams","primary_email":"wthams0@liveinternet.ru","primary_phone":"(540) 2120230","company":"Twimm","title":"Associate Professor","street_address":null,"city":null,"state":null,"country":null,"zip_code":null},
{"id":"899f36ae-fd28-40da-ae7d-47c9bb64a3e4","first_name":"Nyssa","middle_name":"Ruttger","last_name":"Orritt","primary_email":"rorritt1@lulu.com","primary_phone":"(713) 3773168","company":null,"title":null,"street_address":null,"city":null,"state":null,"country":null,"zip_code":null},
{"id":"a75d22de-5561-4974-8479-19907362af03","first_name":"Robert","middle_name":"Frank","last_name":"Mersh","primary_email":"fmersh2@dailymotion.com","primary_phone":"(571) 4435371","company":"Zava","title":null,"street_address":"41 Kropf Drive","city":"Arlington","state":"Virginia","country":"United States","zip_code":"22217"},
{"id":"4ceff742-168e-4fcc-9565-f272a6d167dd","first_name":"Verla","middle_name":"Adaline","last_name":"Kohnen","primary_email":"akohnen3@vkontakte.ru","primary_phone":"(404) 2538766","company":null,"title":null,"street_address":null,"city":null,"state":null,"country":null,"zip_code":null},
{"id":"d7b1a8db-aec2-4c7e-877d-e6ada37419b5","first_name":"Lauree","middle_name":"Rickard","last_name":"Males","primary_email":"rmalesrr@ftc.gov","primary_phone":"(202) 1866162","company":"BlogXS","title":"Electrical Engineer","street_address":null,"city":null,"state":null,"country":null,"zip_code":null}]

export const COMPANY_DATA =
[{"id":1,"name":"Edgetag","street_address":null,"city":null,"state":null,"country":null,"contacts":4},
{"id":2,"name":"Camimbo","street_address":null,"city":null,"state":null,"country":null,"contacts":10},
{"id":3,"name":"Topicblab","street_address":null,"city":null,"state":null,"country":null,"contacts":17},
{"id":4,"name":"Kimia","street_address":"09 Paget Road","city":"Manassas","state":"Virginia","country":"United States","contacts":1},
{"id":5,"name":"Geba","street_address":null,"city":null,"state":null,"country":null,"contacts":15},
{"id":6,"name":"Skipstorm","street_address":null,"city":null,"state":null,"country":null,"contacts":7},
{"id":7,"name":"Avamba","street_address":null,"city":null,"state":null,"country":null,"contacts":20},
{"id":8,"name":"Plajo","street_address":null,"city":null,"state":null,"country":null,"contacts":8},
{"id":9,"name":"Zoonder","street_address":null,"city":null,"state":null,"country":null,"contacts":11},
{"id":10,"name":"Zooxo","street_address":null,"city":null,"state":null,"country":null,"contacts":10},
{"id":11,"name":"Gabspot","street_address":null,"city":null,"state":null,"country":null,"contacts":20},
{"id":12,"name":"Livetube","street_address":null,"city":null,"state":null,"country":null,"contacts":4},
{"id":13,"name":"Pixoboo","street_address":null,"city":null,"state":null,"country":null,"contacts":19},
{"id":14,"name":"Quinu","street_address":null,"city":null,"state":null,"country":null,"contacts":4},
{"id":15,"name":"Divavu","street_address":null,"city":null,"state":null,"country":null,"contacts":1},
{"id":16,"name":"Rhynyx","street_address":"7140 Spohn Crossing","city":"Oklahoma City","state":"Oklahoma","country":"United States","contacts":18},
{"id":17,"name":"Browsecat","street_address":"6 Pepper Wood Crossing","city":"Fort Worth","state":"Texas","country":"United States","contacts":19},
{"id":18,"name":"Voolia","street_address":null,"city":null,"state":null,"country":null,"contacts":1},
{"id":19,"name":"Twitterworks","street_address":null,"city":null,"state":null,"country":null,"contacts":19},
{"id":20,"name":"Dynabox","street_address":null,"city":null,"state":null,"country":null,"contacts":9},
{"id":21,"name":"Yodel","street_address":"652 David Center","city":"Melbourne","state":"Florida","country":"United States","contacts":12},
{"id":22,"name":"Browsecat","street_address":null,"city":null,"state":null,"country":null,"contacts":8},
{"id":23,"name":"Plajo","street_address":null,"city":null,"state":null,"country":null,"contacts":3},
{"id":24,"name":"Blogpad","street_address":null,"city":null,"state":null,"country":null,"contacts":10},
{"id":25,"name":"Voolith","street_address":null,"city":null,"state":null,"country":null,"contacts":12},
{"id":26,"name":"Brightdog","street_address":null,"city":null,"state":null,"country":null,"contacts":18},
{"id":27,"name":"Jaxworks","street_address":"6262 Mendota Crossing","city":"Indianapolis","state":"Indiana","country":"United States","contacts":16},
{"id":28,"name":"Talane","street_address":null,"city":null,"state":null,"country":null,"contacts":6},
{"id":29,"name":"Tagcat","street_address":null,"city":null,"state":null,"country":null,"contacts":4},
{"id":30,"name":"Talane","street_address":null,"city":null,"state":null,"country":null,"contacts":14}]

export const FORM_DATA = 
[{"id":1,"firstname":"Amabelle","lastname":"Licciardo","email":"alicciardo0@blogtalkradio.com"},
{"id":2,"firstname":"Noah","lastname":"Puttock","email":"nputtock1@pbs.org"},
{"id":3,"firstname":"Filmore","lastname":"Ingry","email":"fingry2@sbwire.com"},
{"id":4,"firstname":"Ollie","lastname":"Skillman","email":"oskillman3@ifeng.com"},
{"id":5,"firstname":"Tabor","lastname":"Kubala","email":"tkubala4@wordpress.com"},
{"id":6,"firstname":"Ruthanne","lastname":"Marston","email":"rmarston5@hp.com"},
{"id":7,"firstname":"Orlan","lastname":"Colbridge","email":"ocolbridge6@wunderground.com"},
{"id":8,"firstname":"Justis","lastname":"McFee","email":"jmcfee7@elegantthemes.com"},
{"id":9,"firstname":"Mella","lastname":"James","email":"mjames8@tamu.edu"},
{"id":10,"firstname":"Sheilah","lastname":"Streader","email":"sstreader9@smh.com.au"},
{"id":11,"firstname":"Gar","lastname":"Ruddle","email":"gruddlea@histats.com"},
{"id":12,"firstname":"Malvina","lastname":"MacKall","email":"mmackallb@tiny.cc"},
{"id":13,"firstname":"Keane","lastname":"Gooms","email":"kgoomsc@weebly.com"},
{"id":14,"firstname":"Lucais","lastname":"Donaher","email":"ldonaherd@slashdot.org"},
{"id":15,"firstname":"Nerty","lastname":"Gallant","email":"ngallante@360.cn"},
{"id":16,"firstname":"Marcelle","lastname":"O'Doran","email":"modoranf@geocities.com"},
{"id":17,"firstname":"Herbie","lastname":"Sperry","email":"hsperryg@alibaba.com"},
{"id":18,"firstname":"Fitz","lastname":"Walcar","email":"fwalcarh@ehow.com"},
{"id":19,"firstname":"Lissie","lastname":"Cakebread","email":"lcakebreadi@wordpress.org"},
{"id":20,"firstname":"Shermy","lastname":"Lakeman","email":"slakemanj@wired.com"}];

export const NOTES_DATA = 
[{"id":1,"message":"Cras pellentesque volutpat dui. Maecenas tristique, est et tempus semper, est quam pharetra magna, ac consequat metus sapien ut nunc.","owner":"Delly Bineham","createdAt":"2020-06-02 21:25:27"},
{"id":2,"message":"Sed ante. Vivamus tortor. Duis mattis egestas metus.","owner":"Peter Ivic","createdAt":"2019-09-02 14:51:00"},
{"id":3,"message":"Donec ut dolor.","owner":"Estella Eddington","createdAt":"2019-09-23 09:05:03"},
{"id":4,"message":"Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia Curae; Donec pharetra, magna vestibulum aliquet ultrices, erat tortor sollicitudin mi, sit amet lobortis sapien sapien non mi. Integer ac neque. Duis bibendum. Morbi non quam nec dui luctus rutrum. Nulla tellus. In sagittis dui vel nisl. Duis ac nibh. Fusce lacus purus, aliquet at, feugiat non, pretium quis, lectus. Suspendisse potenti.","owner":"Morissa McNeachtain","createdAt":"2019-10-26 14:23:38"},
{"id":5,"message":"Ut tellus. Nulla ut erat id mauris vulputate elementum. Nullam varius. Nulla facilisi. Cras non velit nec nisi vulputate nonummy. Maecenas tincidunt lacus at velit. Vivamus vel nulla eget eros elementum pellentesque. Quisque porta volutpat erat. Quisque erat eros, viverra eget, congue eget, semper rutrum, nulla. Nunc purus.","owner":"Bernard Polin","createdAt":"2020-05-12 14:53:45"},
{"id":6,"message":"Integer aliquet, massa id lobortis convallis, tortor risus dapibus augue, vel accumsan tellus nisi eu orci. Mauris lacinia sapien quis libero. Nullam sit amet turpis elementum ligula vehicula consequat. Morbi a ipsum. Integer a nibh.","owner":"Amara Losseljong","createdAt":"2019-08-22 10:43:21"}];

export const VIP_DATA = [
  // {"id":0, "name":"BRONZE"},
  // {"id":1, "name":"SILVER"},
  // {"id":2, "name":"GOLD"},
  // {"id":3, "name":"PLATINUM"}

  {"id":0, "name":"SILVER"},
  {"id":1, "name":"GOLD"}
]

export const PIPELINE_PHASE = [
  {"id":0, "value":"QUALIFIEDLEAD", "label":"Qualified Lead", "color": "#caf0f8" },
  {"id":1, "value":"PRESENTATIONMEETING", "label":"Presentation/meeting", "color": "#fadde1"},
  {"id":2, "value":"INFORMALPRICING", "label":"Informal Pricing", "color": "#ffc971"},
  {"id":3, "value":"FORMALQUOTE", "label":"Formal Quote", "color": "#cbb2fe"},
  {"id":4, "value":"CONFIRMEDPAYMENTPENDING", "label":"Confirmed/payment pending", "color": "#ffee99"},
  {"id":5, "value":"CLOSEDWON", "label":"Closed Won", "color": "#a7c957"},
  {"id":6, "value":"CLOSEDLOST", "label":"Closed Lost", "color": "#df8080"}
];

export const CLOSED_LOST_OPTIONS = [
  {"id": 0, "label": "Lead time too long"},
  {"id": 1, "label": "Price too high"},
  {"id": 2, "label": "Design/style didn't work"},
  {"id": 3, "label": "Finishes won't work"},
  {"id": 4, "label": "Lost project"},
];

export const SALE_AMOUNT = [
  {"id":0, "name":"Less than $20,000"},
  {"id":1, "name":"$20,000 - $40,000"},
  {"id":2, "name":"$40,000 - $60,000"},
  {"id":3, "name":"$60,000 - $80,000"},
  {"id":4, "name":"$100,000 - $120,000"},
  {"id":9, "name":"Greater than $120,000"},
];

export const PIPELINE_HEAD = [
  {id: "name", label: "Contact"},
  {id: "company", label: "Company"},
  {id: "project_title", label: "Project"},
  {id: "phase", label: "Phase", align: 'center'},
  {id: "sale_amount", label: "Sale Amount"},
  {id: "assignedTo", label: "Assigned To"},
  {id: "createdAt", label: "Date Added"},
  {id: "actions", label: "Actions", align: 'center'}
];

export const CONTACTS_OPTIONS_SORT_BY = [
  { label: 'First Name (ASC)', value: { type: 'first_name', direction: 'ASC' } },
  { label: 'First Name (DESC)', value: { type: 'first_name', direction: 'DESC' } },
  { label: 'Last Name (ASC)', value: { type: 'last_name', direction: 'ASC' } },
  { label: 'Last Name (DESC)', value: { type: 'last_name', direction: 'DESC' } },
  { label: 'Created At (ASC)', value: { type: 'createdAt', direction: 'ASC' }},
  { label: 'Created At (DESC)', value: { type: 'createdAt', direction: 'DESC' }},
  { label: 'Updated At (ASC)', value: { type: 'updatedAt', direction: 'ASC' }},
  { label: 'Updated At (DESC)', value: { type: 'updatedAt', direction: 'DESC' }},
];

export const COMPANY_OPTIONS_SORT_BY = [
  { label: 'Name (ASC)', value: { type: 'name', direction: 'ASC' } },
	{ label: 'Name (DESC)', value: { type: 'name', direction: 'DESC' } },
  { label: 'Created At (ASC)', value: { type: 'createdAt', direction: 'ASC' }},
  { label: 'Created At (DESC)', value: { type: 'createdAt', direction: 'DESC' }},
  { label: 'Updated At (ASC)', value: { type: 'updatedAt', direction: 'ASC' }},
  { label: 'Updated At (DESC)', value: { type: 'updatedAt', direction: 'DESC' }},
];

export const CURRENT_VERSION = "1.8.1"