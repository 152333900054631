import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../use-actions';

export const useCreateGroupCompany = () => {
	const { createGroupCompany } = useActions(actions);
	return async values => {
		return await createGroupCompany({ input: values });
	}
};

export const useDeleteGroupCompany = () => {
	const { deleteGroupCompany } = useActions(actions);
	return async values => {
		return await deleteGroupCompany({ input: values });
	}
};