import { createAction } from 'redux-actions';

export const updateFilterContacts = createAction(
    'UPDATE_FILTER_CONTACTS', 
    async value => await value
);

export const resetAllFilterContacts = createAction(
    'RESET_ALL_FILTER_CONTACTS', 
    async value => await value
);

export const resetOneFilterContacts = createAction(
    'RESET_ONE_FILTER_CONTACTS', 
    async value => await value
);