import { Auth } from 'aws-amplify';
import axios from "axios";
import { useSnackbar } from 'notistack';
import get from 'lodash/fp/get';


export const useSendEmailNotification = () => {
  const { enqueueSnackbar } = useSnackbar();
  const apiUrl = "https://ilqqatv323.execute-api.us-west-2.amazonaws.com/default/panafold-services-simple-message-sender";

  return async (values) => {
    const { assignees } = values;
    for (let assigned of assignees) {
      if (assigned.id !== Auth.user.attributes.sub) {
        const body = {
          Subject: `You've been assigned to a new ${get("type", values)}!`,
          Emails: [{
            Email: assigned.email,
            Content: {
              user: assigned.name,
              recordName: get("recordName", values),
              recordId: get("id", values),
              type: get("type", values),
              lowerCaseType: get("type", values).toLowerCase(),
              tenant: "33sixty",
              pipeline: (get("type", values) === "Pipeline") ? true : false
            }
          }],
          Template: "33sixtyConnectAssignedItem"
        };

        try {
          enqueueSnackbar(`Sending notification email...`);
          await axios.post(apiUrl, body);
          enqueueSnackbar(`Notification email sent!`, { variant: 'success' });
        } catch (error) {
          console.error('Error sending email:', error);
          enqueueSnackbar('Failed to send notification email.', { variant: 'error' });
        }
      }
    }
  };
};