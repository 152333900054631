import { useSelector } from "react-redux";

import * as actions from '../../redux/actions/crm';
import useActions from '../use-actions';

export const useNotifications = () => {
	const notifications = useSelector(state => 
		state.crm.notifications 
		? state.crm.notifications
		: []
	);
	return notifications;
  };

export const useCreateNotification = () => {
	const createNotification = useActions(actions.createNotification);
	return value => {
		return createNotification(value);
	};
};