import { createAction } from 'redux-actions';

export const updateFilterCompanys = createAction(
    'UPDATE_FILTER_COMPANYS', 
    async value => await value
);

export const resetAllFilterCompanys = createAction(
    'RESET_ALL_FILTER_COMPANYS', 
    async value => await value
);

export const resetOneFilterCompanys = createAction(
    'RESET_ONE_FILTER_COMPANYS', 
    async value => await value
);