import { API, graphqlOperation } from 'aws-amplify';
import get from "lodash/fp/get";

import { getGmailConfigByCognitoEmail, updateGmailConfig } from '../../src';

export const getEmailSignature = async (userEmail) => {
  try {
    const { data } = await API.graphql(graphqlOperation(getGmailConfigByCognitoEmail, { cognito_user_email: userEmail }));
    const item = data.getGmailConfigByCognitoEmail.items[0];
    const signature = get('signature', item) || "";
    return signature;
  } catch (e) {
    console.log(e);
    return e;
  }
};

export const updateEmailSignature = async (values) => {
  // console.log(values)
  try {
    const { cognito_user_email, signature } = values;
    // get gmail config by cognito user email
    const { data } = await API.graphql(graphqlOperation(getGmailConfigByCognitoEmail, { cognito_user_email }));
    // console.log('gmailConfig', data)
    const item = data.getGmailConfigByCognitoEmail.items[0];
    const id = get('id', item) || null;
    // console.log(id)
    if (id) {
      // found: update
      const { data } = await API.graphql(graphqlOperation(updateGmailConfig, { input: { id, signature } }));
      // console.log('updated', data)
      return data.updateGmailConfig.signature;
    }
  } catch (e) {
    console.log(e)
    return e;
  }
};

export default { getEmailSignature, updateEmailSignature };