import { useState, useEffect } from "react";
import { Auth, API, graphqlOperation } from "aws-amplify";
import moment from 'moment';
import get from 'lodash/fp/get';
import _find from 'lodash/find';

import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../use-actions';
import { listMailchimpConfigs } from "../../src";
import { useCreateNotification } from "./use-notification";

// Get mailchimp config for "Settings" in the UserMenu
// Only users with mailchimp login credentials can change the "Settings"
export const getConfigForSettings = userEmail => {
	return API.graphql(graphqlOperation(listMailchimpConfigs, {
		filter: { cognito_user_email: { eq: userEmail } },
		limit: 1000
	}))
		.then(res => res.data.listMailchimpConfigs.items[0]);
};

// Get mailchimp config for general requests between 33Sixty mailchimp's account and Connect App
export const getConfig = () => {
	return API.graphql(graphqlOperation(listMailchimpConfigs, {
		filter: { accountname: { eq: '33Sixty' } },
		limit: 1000
	}))
		.then(res => res.data.listMailchimpConfigs.items[0]);
};

export const useLists = userEmail => {
	const [lists, setLists] = useState([]);
	useEffect(() => {
		async function fetchData() {
			const config = await getConfigForSettings(userEmail);
			if (config) {
				try {
					const init = { body: { config } };
					const response = await API.post('mailchimpEndpointsAPI', '/getLists', init);
					const lists = response.data.lists.map(({ id, name }) => ({ id, name }));
					setLists(lists);
				} catch (err) {
					console.log(err);
				}
			}
		}
		fetchData();
	}, []);
	return lists;
};

export const useUser = userEmail => {
	const [user, setUser] = useState(null);
	useEffect(() => {
		async function fetchData() {
			const config = await getConfigForSettings(userEmail);
			if (config) {
				setUser(config.mailchimp_user_email)
			}
		}
		fetchData();
	}, []);
	return user;
};

export const useGetMemberActivity = async ({ list_id, mailchimp_id }) => {
	try {
		const config = await getConfig();
		const init = {
			body: {
				config,
				list_id,
				subscriber_hash: mailchimp_id,
			}
		};
		const memberActivity = await API.post('mailchimpEndpointsAPI', '/getMemberActivity', init);
		// console.log(memberActivity)
		return memberActivity;
	} catch (err) {
		console.log(err)
	}
};

export const useGetMailchimpStatus = ({ group, mailchimp_id }) => {
	const [status, setStatus] = useState(null);
	const [loading, setLoading] = useState(false);
	const fetchData = async () => {
		setLoading(true);
		if (group && group.mailchimp_list_id) {
			try {
				const config = await getConfig();
				const init = {
					body: {
						config,
						list_id: group.mailchimp_list_id,
						subscriber_hash: mailchimp_id,
						fields: "id,email_address,status,tags"
					}
				};
				const memberInfo = await API.post('mailchimpEndpointsAPI', '/getMember', init)
				if (memberInfo.error){
					setStatus({ error: 'Not found. Please contact support.'});
				} else {
					setStatus(memberInfo.data.status);
				}
			} catch (e) {
				console.log(e);
				setStatus({ error: 'Not found. Please contact support.'});
			}
		}
		setLoading(false);
	};
	useEffect(() => { fetchData() }, [group]);
	return [{ status, loading }];
};

export const useGetMailchimpTagStatus = ({ group, newsletter, mailchimp_id }) => {
	const [status, setStatus] = useState(null);
	const [loading, setLoading] = useState(false);
	const fetchData = async () => {
		setLoading(true);
		if (group && get('mailchimp_list_id', newsletter)) {
			try {
				const config = await getConfig();
				const init = {
					body: {
						config,
						list_id: newsletter.mailchimp_list_id,
						subscriber_hash: mailchimp_id,
						fields: "id,email_address,status,tags"
					}
				};
				const memberInfo = await API.post('mailchimpEndpointsAPI', '/getMember', init)
				if (memberInfo.error){
					setStatus({ error: 'Not found. Please contact support.'});
				} else if(_find(get('data.tags',memberInfo), ['name', group.mailchimp_tag_name])){
					setStatus('tagged');
				}
			} catch (e) {
				console.log(e);
				setStatus({ error: 'Not found. Please contact support.'});
			}
		}
		setLoading(false);
	};
	useEffect(() => { fetchData() }, [group]);
	return [{ status, loading }];
};

export const useGetCampaignRecipients = async ({ campaign_id }) => {
	try {
		const config = await getConfig();
		const init = {
			body: {
				config,
				campaign_id
			}
		};
		const campaignRecipients = await API.post('mailchimpEndpointsAPI', '/getCampaignRecipients', init);
		// console.log(campaignRecipients)
		return campaignRecipients;
	} catch (err) {
		console.log(err)
	}
};

export const useGetCampaign = ({ campaign_id }) => {
	const [campaign, setCampaign] = useState({});
	const fetchData = async () => {
		try {
			const config = await getConfig();
			const init = {
				body: {
					config,
					campaign_id,
				}
			};
			const campaign = await API.post('mailchimpEndpointsAPI', '/getCampaign', init);
			// console.log(campaign)
			setCampaign({ campaign_id, campaign_url: campaign.data.archive_url })
		} catch (err) {
			console.log(err)
		}
	}
	useEffect(() => { fetchData() }, []);
	return [{ campaign }];
};

export const useUpdateMember = async ({ list_id, mailchimp_id, values }) => {
	try {
		const config = await getConfig();
		const init = {
			body: {
				config,
				list_id,
				subscriber_hash: mailchimp_id,
				values
			}
		};
		const updatedMember = await API.post('mailchimpEndpointsAPI', '/updateMember', init);
		// console.log(updatedMember)
		return updatedMember
	} catch (err) {
		console.log(err)
	}
};

export const useUpdateMemberTag = async ({ list_id, mailchimp_id, tag, status }) => {
	try{
		const config = await getConfig();
		const init = {
			body: {
				config,
				list_id: list_id,
				subscriber_hash: mailchimp_id,
				tag_name: tag,
				status: status
			}
		};
		const updatedMemberTag = await API.post('mailchimpEndpointsAPI', '/updateMemberTags', init);
		return updatedMemberTag;
	} catch (err) {
		console.log(err)
	}
}

const getTimeStamp = () => moment().format('MMMM Do YYYY, h:mm:ss a');

const useOnSync = () => {
	const createNotification = useCreateNotification();
	return async ({ el, config }) => {
		// console.log(el)
		const init = {
			body: {
				list_id: el.list_id,
				crm_group: el.crm_group,
				mailchimp_api_endpoint: config.api_endpoint,
				mailchimp_token: config.access_token
			}
		};
		try {
			// Sends post request for mailchimpSync API Gateway and invokes mailchimpSync lambda function
			const response = await API.post('mailchimpSyncAPI', '/onsync', init);
			// console.log(response);

			// Redux
			const newNotification = createNotification({
				timeStamp: getTimeStamp(),
				text: `Sync started between "${el.crm_group.name}" CRM Group and "${el.list_name}" Mailchimp Audience.`
			});
			return newNotification;
		} catch (err) {
			console.log(err);

			// Redux
			const newNotification = createNotification({
				timeStamp: getTimeStamp(),
				text: `Error on sync "${el.crm_group.name}" CRM Group with "${el.list_name}" Mailchimp Audience.\nPlease contact support.`
			});
			return newNotification;
		}
	}
};

const useOffSync = () => {
	/*
		If crm_group is synced with audience:
		Send request to mailchimp to delete webhook
			https://mailchimp.com/developer/reference/lists/list-webhooks/#delete_/lists/-list_id-/webhooks/-webhook_id-
			input {
			list_id
			webhook_id
			}
		Graphql mutation: update group
		input: {
			id group
			mailchimp_list_id: null
			mailchimp_webhook_id: null
		}
	*/
	const { updateGroup } = useActions(actions);
	const createNotification = useCreateNotification();
	return async ({ el, config }) => {
		const { crm_group } = el;
		try {
			if (crm_group.mailchimp_list_id) {
				const init = {
					body: {
						config,
						list_id: crm_group.mailchimp_list_id,
						webhook_id: crm_group.mailchimp_webhook_id
					}
				};
				const removeWebhook = await API.post('mailchimpEndpointsAPI', '/deleteWebhook', init);
				// console.log('REMOVE WEBHOOK', removeWebhook);
				const updatedGroup = await updateGroup({
					input: {
						id: crm_group.id,
						mailchimp_list_id: null,
						mailchimp_webhook_id: null
					}
				});
				// console.log('UPDATED GROUP', updatedGroup);
			}
			// Redux
			const newNotification = createNotification({
				timeStamp: getTimeStamp(),
				text: `Sync deactivated between "${el.crm_group.name}" CRM Group and "${el.list_name}" Mailchimp Audience.`
			});
			return newNotification;
		} catch (err) {
			console.log(err);

			// Redux
			const newNotification = createNotification({
				timeStamp: getTimeStamp(),
				text: `Error on deactivating sync "${el.crm_group.name}" CRM Group with "${el.list_name}" Mailchimp Audience.\nPlease contact support.`
			});
			return newNotification;
		}
	}
}

export const useSync = userEmail => {
	const onSync = useOnSync();
	const offSync = useOffSync();
	return async values => {
		const config = await getConfigForSettings(userEmail);
		const activate = values.syncs
			.filter(el => el.sync === true && el.crm_group.mailchimp_list_id !== el.list_id)
			.map(el => onSync({ el, config }));
		const deactivate = values.syncs
			.filter(el => el.sync === false && el.crm_group)
			.map(el => offSync({ el, config }));
		try {
			return {
				activateSync: await Promise.all(activate).then(res => res).catch(err => err),
				deactivateSync: await Promise.all(deactivate).then(res => res).catch(err => err),
			}
		} catch (err) {
			console.log(err);
			return err;
		}
	}
};