import { useState, useEffect } from "react";
import sortBy from 'lodash/sortBy';

import { default as actions } from '../../redux/actions/amplifyActions';
import { default as useActions } from '../use-actions';

export const useCreateGroup = () => {
  const { createGroup } = useActions(actions);
  return values => {
    return createGroup({
      input: {
        name: values.name,
      }
    });
  }
};

export const useGroups = (trigger) => {
  const { getGroups } = useActions(actions);
  const [groups, setGroups] = useState([]);
  const fetchGroups = async () => {
    try {
      const data = await getGroups({ limit: 1000 });
      setGroups(sortBy(data.value.listGroups.items, ['name']));
    } catch (e) {
      console.log(e);
    }
  };
  useEffect(() => {
    fetchGroups();
  }, [trigger]);
  return [{ groups }];
};

// export const useFetchGroupsWithContacts = () => {
//   const { getGroupsWithContacts } = useActions(actions);
//   return async values => {
//     // console.log(values)
//     const data = await getGroupsWithContacts({ limitGroups: 1000, limitContacts: 20000, filter: values });
//     return data;
//   }
// };

export const useReassignGroupContact = () => {
  const { createGroupContact, deleteGroupContact, getContactsWithGroups } = useActions(actions);

  return async (values) => {
    const {
      selected,
      unsetMailings,
      groupMailings,
      groupRegion,
      groupIndustry
    } = values;

    try {
      let token = null;
      let contacts = [];
      while(true){
        const data = await getContactsWithGroups({ 
          limit: 3000, 
          nextToken: token,
          filter: { or: selected.map(id => ({ id: { eq: id } })) },
        });
        token = data.value.listContacts.nextToken;
        contacts = contacts.concat(data.value.listContacts.items);
        if(token === null) break;
      }
      console.log('CONTACTS', contacts)

      /* In case unsetMailings is true: remove all groupContacts type mailings */
      if (unsetMailings) {
        // console.log('unsetMailings',unsetMailings)
        contacts.map(async contact => {
          const groupContacts = contact.groups.items.filter(({ group }) => group.type === 'mailings');
          // console.log('groupContacts', groupContacts)
          const deletedGroupContacts = await Promise.all(groupContacts.map(groupContact => {
            deleteGroupContact({ input: { id: groupContact.id } });
          }));
          // console.log('deletedGroupContacts', deletedGroupContacts)
        })
      }

      /*
        In case groupMailings, groupRegion or groupIndustry are selected:
        For each contact selected:
          - Check if contact has groupContact with selected group
          - In case groupContact not found for selected group: create groupContact
          - Then, map all groupContacts for current contact and delete old groupContacts of same type (mailings, region or industry), 
          except the selected group.
      */

      if (groupMailings) {
        // console.log('groupMailings', groupMailings)
        contacts.map(contact => {
          const hasContactGroupSelected = Boolean(contact.groups.items.find(({ group }) => group.id === groupMailings.id));
          // console.log('hasContactGroupSelected', hasContactGroupSelected)
          if (!hasContactGroupSelected) {
            const createdGroupContact = createGroupContact({
              input: {
                groupContactContactId: contact.id,
                groupContactGroupId: groupMailings.id,
                mailchimp_status: 'subscribed'
              }
            });
            // console.log('createdGroupContact', createdGroupContact)
          }
          contact.groups.items.map(groupContact => {
            const { group } = groupContact;
            if (group.type === 'mailings' && group.id !== groupMailings.id) {
              const deletedGroupContact = deleteGroupContact({ input: { id: groupContact.id } });
              // console.log('deletedGroupContact', deletedGroupContact);
            }
          })
        })
      }

      if (groupRegion) {
        // console.log('groupRegion', groupRegion)
        contacts.map(contact => {
          const hasContactGroupSelected = Boolean(contact.groups.items.find(({ group }) => group.id === groupRegion.id));
          // console.log('hasContactGroupSelected', hasContactGroupSelected)
          if (!hasContactGroupSelected) {
            const createdGroupContact = createGroupContact({
              input: {
                groupContactContactId: contact.id,
                groupContactGroupId: groupRegion.id,
                mailchimp_status: 'subscribed'
              }
            });
            // console.log('createdGroupContact', createdGroupContact)
          }
          contact.groups.items.map(groupContact => {
            const { group } = groupContact;
            if (group.type === 'region' && group.id !== groupRegion.id) {
              const deletedGroupContact = deleteGroupContact({ input: { id: groupContact.id } });
              // console.log('deletedGroupContact', deletedGroupContact);
            }
          })
        })
      }

      if (groupIndustry) {
        // console.log('groupIndustry', groupIndustry)
        contacts.map(contact => {
          const hasContactGroupSelected = Boolean(contact.groups.items.find(({ group }) => group.id === groupIndustry.id));
          // console.log('hasContactGroupSelected', hasContactGroupSelected)
          if (!hasContactGroupSelected) {
            const createdGroupContact = createGroupContact({
              input: {
                groupContactContactId: contact.id,
                groupContactGroupId: groupIndustry.id,
                mailchimp_status: 'subscribed'
              }
            });
            // console.log('createdGroupContact', createdGroupContact)
          }
          contact.groups.items.map(groupContact => {
            const { group } = groupContact;
            if (group.type === 'industry' && group.id !== groupIndustry.id) {
              const deletedGroupContact = deleteGroupContact({ input: { id: groupContact.id } });
              // console.log('deletedGroupContact', deletedGroupContact);
            }
          })
        })
      }
    } catch (e) {
      console.log(e)
    }
  }
};

export const useReassignGroupCompany = () => {
  const { createGroupCompany, deleteGroupCompany, getCompanysWithGroups } = useActions(actions);

  return async (values) => {
    const {
      selected,
      unsetMailings,
      groupMailings,
      groupRegion,
      groupIndustry
    } = values;

    try {
      let token = null;
      let companys = [];
      while(true){
        const data = await getCompanysWithGroups({ 
          limit: 3000, 
          nextToken: token,
          filter: { or: selected.map(id => ({ id: { eq: id } })) },
        });
        token = data.value.listCompanys.nextToken;
        companys = companys.concat(data.value.listCompanys.items);
        if(token === null) break;
      }
      // console.log('COMPANYS', companys)

      /* In case unsetMailings is true: remove all groupCompanys type mailings */
      if (unsetMailings) {
        // console.log('unsetMailings',unsetMailings)
        companys.map(async company => {
          const groupCompanys = company.groups.items.filter(({ group }) => group.type === 'mailings');
          // console.log('groupCompanys', groupCompanys)
          const deletedGroupCompanys = await Promise.all(groupCompanys.map(groupCompany => {
            deleteGroupCompany({ input: { id: groupCompany.id } });
          }));
          // console.log('deletedGroupCompanys', deletedGroupCompanys)
        })
      }

      /*
        In case groupMailings, groupRegion or groupIndustry are selected:
        For each company selected:
          - Check if company has groupCompany with selected group
          - In case groupCompany not found for selected group: create groupCompany
          - Then, map all groupCompanys for current company and delete old groupCompanys of same type (mailings, region or industry), 
          except the selected group.
      */

      if (groupMailings) {
        // console.log('groupMailings', groupMailings)
        companys.map(company => {
          const hasCompanyGroupSelected = Boolean(company.groups.items.find(({ group }) => group.id === groupMailings.id));
          // console.log('hasCompanyGroupSelected', hasCompanyGroupSelected)
          if (!hasCompanyGroupSelected) {
            const createdGroupCompany = createGroupCompany({
              input: {
                groupCompanyCompanyId: company.id,
                groupCompanyGroupId: groupMailings.id,
                mailchimp_status: 'subscribed'
              }
            });
            // console.log('createdGroupCompany', createdGroupCompany)
          }
          company.groups.items.map(groupCompany => {
            const { group } = groupCompany;
            if (group.type === 'mailings' && group.id !== groupMailings.id) {
              const deletedGroupCompany = deleteGroupCompany({ input: { id: groupCompany.id } });
              // console.log('deletedGroupCompany', deletedGroupCompany);
            }
          })
        })
      }

      if (groupRegion) {
        // console.log('groupRegion', groupRegion)
        companys.map(company => {
          const hasCompanyGroupSelected = Boolean(company.groups.items.find(({ group }) => group.id === groupRegion.id));
          // console.log('hasCompanyGroupSelected', hasCompanyGroupSelected)
          if (!hasCompanyGroupSelected) {
            const createdGroupCompany = createGroupCompany({
              input: {
                groupCompanyCompanyId: company.id,
                groupCompanyGroupId: groupRegion.id,
                mailchimp_status: 'subscribed'
              }
            });
            // console.log('createdGroupCompany', createdGroupCompany)
          }
          company.groups.items.map(groupCompany => {
            const { group } = groupCompany;
            if (group.type === 'region' && group.id !== groupRegion.id) {
              const deletedGroupCompany = deleteGroupCompany({ input: { id: groupCompany.id } });
              // console.log('deletedGroupCompany', deletedGroupCompany);
            }
          })
        })
      }

      if (groupIndustry) {
        // console.log('groupIndustry', groupIndustry)
        companys.map(company => {
          const hasCompanyGroupSelected = Boolean(company.groups.items.find(({ group }) => group.id === groupIndustry.id));
          // console.log('hasCompanyGroupSelected', hasCompanyGroupSelected)
          if (!hasCompanyGroupSelected) {
            const createdGroupCompany = createGroupCompany({
              input: {
                groupCompanyCompanyId: company.id,
                groupCompanyGroupId: groupIndustry.id,
                mailchimp_status: 'subscribed'
              }
            });
            // console.log('createdGroupCompany', createdGroupCompany)
          }
          company.groups.items.map(groupCompany => {
            const { group } = groupCompany;
            if (group.type === 'industry' && group.id !== groupIndustry.id) {
              const deletedGroupCompany = deleteGroupCompany({ input: { id: groupCompany.id } });
              // console.log('deletedGroupCompany', deletedGroupCompany);
            }
          })
        })
      }
    } catch (e) {
      console.log(e)
    }
  }
};