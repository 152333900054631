import React from "react";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

const styles = {
  root: {
    width: '100vw',
    height: '100vh',
    alignItems: 'flex-end',
    paddingBottom: '10px',
  },
  backdrop: {
    background: 'rgba(0, 0, 0, 0.5)',
  },
  anchor: {
    bottom: 0
  }
};

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />
};

const SnackProgress = ({ progress, classes, backdrop }) => (
    <Snackbar
      open={true}
      classes={{ root: clsx(classes.root, backdrop? classes.backdrop: null), anchorOriginBottomCenter: classes.anchor }}
    >
      <Alert severity={progress.severity} action={progress.action}>
        {progress.text}
      </Alert>
    </Snackbar>
);

SnackProgress.defaultProps = {
  backdrop: true
}

export default withStyles(styles)(SnackProgress);