import { createAction } from 'redux-actions';
import API from '../../../api/gmail/Signature';

export const updateEmailDraft = createAction(
  'UPDATE_EMAIL_DRAFT', 
  async value => await value
);

export const deleteEmailDraft = createAction(
  'DELETE_EMAIL_DRAFT', 
  async value => await value
);

export const getEmailSignature = createAction(
  'GET_EMAIL_SIGNATURE',
  async userEmail => {
    const response = await API.getEmailSignature(userEmail);
    return response;
  }
);

export const updateEmailSignature = createAction(
  'UPDATE_EMAIL_SIGNATURE',
  async values => {
    const response = await API.updateEmailSignature(values);
    // console.log(response)
    return response;
  }
); 